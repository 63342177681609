import { fetchTimeline } from '@moar/data';
import { PageIntro } from '@moar/ui';
import { TypePageIntroProps } from '@moar/types';
import { GetStaticProps } from 'next';

const apiUrl = process.env['NX_API_URL_TIMELINE_AMREV'];
const apiUrlPreview = process.env['NX_API_URL_TIMELINE_AMREV_PREVIEW'];

export const getStaticProps: GetStaticProps = async ({ params = {}, preview = false }) => {
    const data = await fetchTimeline({
        apiUrl: preview ? apiUrlPreview : apiUrl,
        isProduction: process.env.NODE_ENV === 'production',
    });

    return {
        props: {
            timelineTitle: data.title,
            intro: data.intro,
            linkToSite: data.misc.linkToSite,
            title: data.title,
            paragraph: data.paragraph,
            preview,
            seo: data.seo,
        },
    };
};

export function Index(props: TypePageIntroProps) {
    return (
        <div className="bg-red">
            <PageIntro {...props} />
        </div>
    );
}

export default Index;
